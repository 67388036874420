<template>
  <div class="zy2-item3">
    <h3>推荐功能 · RECOMMEND</h3>
    <ul class="zy2-item3-list">
      <li v-for="(item, index) in list" :key="index">
        <img :src="item.img" />
        <div>
          <h3>{{ item.title }}</h3>
          <p>{{ item.remark }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        { img: '/static/img/zy2-img7.png', title: '统一业务平台', remark: '支持统一应用生态的建设和运营，统一标准支撑平台，助力业务融合，建设重复建设。' },
        { img: '/static/img/zy2-img8.png', title: '超大组织管理', remark: '支持超大组织管理 ，可实现百万人员轻松管理；分级权限精细管理实现千人千面的工作台。' },
        { img: '/static/img/zy2-img9.png', title: '多种部署方式', remark: '成熟支持飞天云专有部署，满足各类应用环境需求。' },
        { img: '/static/img/zy2-img10.png', title: '安全全面护航', remark: '国密算法、等保三级 ，全面安全护航数字化协同办公。' }
      ]
    }
  },
}
</script>
<style lang="less" scoped>
.zy2-item3 {
  padding: 25px 10px;

  >h3 {
    text-transform: uppercase;
    font-size: 18px;
    color: #383838;
    font-weight: bold;
    text-align: center;
    margin-bottom: 35px;
  }

  .zy2-item3-list {
    display: flex;
    flex-flow: column;

    >li {
      padding: 20px;
      color: #383838;
      position: relative;
      margin: initial;
      display: flex;
      align-items: center;

      >img {
        width: 60px;
        height: 60px;
        display: block;
        margin: 0;
        margin-right: 20px;
        float: left;
      }

      >div {

        >h3 {
          margin-top: 10px;
          font-size: 15px;
          font-weight: bold;
          text-align: left;
        }

        >p {
          font-size: 13px;
          font-weight: 300;
          line-height: 1.5;
          margin-top: 10px;
          text-align: left;
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 3px;
        bottom: 3px;
        right: 3px;
        background: #FFFFFF;
        border: 1px solid #E8EAED;
        z-index: -1;
      }
    }
  }
}
</style>