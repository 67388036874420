<template>
  <div class="zy2-item4">
    <h3>客户案例 · CASES</h3>
    <ul class="zy2-item4-list">
      <li v-for="(item, index) in list" :key="index">
        <h3>{{ item.title }}</h3>
        <img :src="item.img" />
        <p>{{ item.remark }}</p>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        { img: '/static/img/zy2-img4.png', title: '浙政钉', remark: '“浙政钉”按照统分结合原则，由浙江省大数据局牵头统一设计建设，各使用单位结合自身业务需求分别建设自建应用，最终形成全省统一的掌上政务协同总平台。目前浙政钉用户数已突破140万人，创建51万个内部工作群。' },
        { img: '/static/img/zy2-img5.png', title: '赣政通', remark: '“赣政通”引入人工智能、大数据和低代码开发理念，按照“统一平台、一体在线、协同高效”原则和“大数据后台、强能力中台、泛应用前台”的理念，构建全省政务协同大平台，已顺利实现省、市、县三级政府部门全覆盖。' },
        // { img: '/static/img/zy2-img6.png', title: 'X政钉', remark: '“X政钉”本地化部署于本地政务云，创新业务融合与数据安全模式，旨在打造全国领先、全省第一的政务协同大平台，目前X政钉已有全市近10万公务员在线协同办公，覆盖市、区县、乡镇街道三级。' }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.zy2-item4 {
  padding: 25px 10px;
  background-color: #F6F6F8;

  >h3 {
    text-transform: uppercase;
    font-size: 18px;
    color: #383838;
    font-weight: bold;
    text-align: center;
    margin-bottom: 35px;
  }

  .zy2-item4-list {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    >li {
      width: 50%;
      padding: 15px 15px 20px;
      color: #383838;
      position: relative;
      margin: initial;

      >img {
        width: 100%;
        display: block;
        margin: 10px 0;
        z-index: 2;
        position: relative;
      }

      >h3 {
        font-size: 15px;
        font-weight: 300;
        text-align: center;
        text-transform: uppercase;
        z-index: 2;
        position: relative;
      }

      >p {
        font-size: 13px;
        font-weight: 300;
        line-height: 1.5;
        z-index: 2;
        position: relative;
        text-align: justify;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 3px;
        bottom: 3px;
        right: 3px;
        background: #FFFFFF;
        border: 1px solid #E8EAED;
      }
    }
  }
}
</style>