<template>
  <div class="zy2-item2">
    <h3>产品优势 · advantage</h3>
    <ul class="zy2-item2-list">
      <li v-for="(item, index) in list" :key="index">
        <img :src="item.img" />
        <div>
          <h3>{{ item.title }}</h3>
          <p>{{ item.remark }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        { img: require('@/assets/dingtalk/dd-icon1.png'), title: '硬核技术', remark: '快速响应各行业业务领域创新' },
        { img: require('@/assets/dingtalk/dd-icon2.png'), title: '部署方式', remark: '灵活支持专网部署和本地部署' },
        { img: require('@/assets/dingtalk/dd-icon3.png'), title: '开放集成', remark: '提供1700+更开放设计与集成能力高效接入业务应用' }
      ]
    }
  },
}
</script>
<style lang="less" scoped>
.zy2-item2 {
  padding: 25px 10px;

  >h3 {
    text-transform: uppercase;
    font-size: 18px;
    color: #383838;
    font-weight: bold;
    text-align: center;
    margin-bottom: 35px;
  }

  .zy2-item2-list {
    display: flex;
    flex-flow: column;

    >li {
      padding: 20px;
      color: #383838;
      position: relative;
      margin: initial;
      display: flex;
      align-items: center;

      >img {
        width: 60px;
        height: 60px;
        display: block;
        margin: 0;
        margin-right: 20px;
        float: left;
      }

      >div {

        >h3 {
          margin-top: 10px;
          font-size: 15px;
          font-weight: bold;
          text-align: left;
        }

        >p {
          font-size: 13px;
          font-weight: 300;
          line-height: 1.5;
          margin-top: 10px;
          text-align: left;
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 3px;
        bottom: 3px;
        right: 3px;
        background: #FFFFFF;
        border: 1px solid #E8EAED;
        z-index: -1;
      }
    }
  }
}
</style>