<template>
  <div class="dd-banner">
    <h3>专有钉钉—数字化协同平台</h3>
    <p>强协同、提效能、助决策、保安全<br />通过云智能和移动互联网技术<br />
      助力大型政企组织数字化转型</p>
    <button @click="openService">立即咨询</button>
  </div>
  <DDZY2Item1/>
  <DDZY2Item2/>
  <DDZY2Item3/>
  <DDZY2Item4/>
</template>
<script>
import DDZY2Item1 from '../../components/dingtalk/DDZY2Item1.vue';
import DDZY2Item2 from '../../components/dingtalk/DDZY2Item2.vue';
import DDZY2Item3 from '../../components/dingtalk/DDZY2Item3.vue';
import DDZY2Item4 from '../../components/dingtalk/DDZY2Item4.vue';
export default {
  components: {
    DDZY2Item1,
    DDZY2Item2,
    DDZY2Item3,
    DDZY2Item4
  }
}
</script>
<style lang="less" scoped>
.dd-banner {
  padding: 65px 25px;
  background: url(/static/img/dd-banner.jpg) no-repeat center;
  background-size: cover;
  color: #fff;

  >h3 {
    font-size: 20px;
    font-weight: bold;
  }

  >p {
    font-size: 15px;
    font-weight: 300;
    line-height: 1.8;
    margin-top: 10px;
  }

  >button {
    height: 40px;
    background: #F23D49;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 30px;
    border: 0 none;
    width: 115px;
  }
}
</style>
