<template>
  <div class="zy2-item1">
    <h3>大型组织数字化协同办公面临的挑战</h3>
    <p>如何应对大型组织行业合规和安全化诉求、业务管理与协同沟通割裂问题，着力搭建一体化成本适宜、安全高效组织协同管理平台。</p>
    <Item v-for="(item,index) in list" :item="item" :key="index"/>
  </div>
</template>
<script>
import Item from './Item.vue';
export default {
  components:{
    Item
  },
  data() {
    return {
      list:[
        {img:'/static/img/zy2-img1.png',title:'大型组织对硬核技术依赖增强',remark:'硬核技术周期长、投入大、维护难，分散企业IT精力，成为业务数字化阻力'},
        {img:'/static/img/zy2-img2.png',title:'移动应用投资高成效低',remark:'缺乏统一标准支撑平台，容易重复建设用户体验参差不齐'},
        {img:'/static/img/zy2-img3.png',title:'难以快速响应业务创新',remark:'多年信息化堆叠，垂直化建设，集成能力和开放能力不符合业务创新'}
      ]
    }
  },
}
</script>
<style lang="less" scoped>
.zy2-item1{
  padding: 25px 15px;
  color: #383838;
  >h3{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  >p{
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 40px;
  }
}
</style>